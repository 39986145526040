import { lazy } from 'react';
const Signup = lazy(() => import('../pages/auth/signup'));
const Login = lazy(() => import('../pages/auth/login'));
const ResetPassword = lazy(() => import('../pages/auth/resetpassword'));
const Landing = lazy(() => import('../components/landing'))
const MarketPlace = lazy(() => import('../components/marketplace'))
const About = lazy(() => import('../components/about'))
const Profile = lazy(() => import('../components/profile/profile'))
const EditProfile = lazy(() => import('../components/editprofile'))
const Artists = lazy(() => import('../components/artists'))
const Drops = lazy(() => import('../components/drops'))
const CreateCollection = lazy(() => import('../components/createcollection'))
const MintNft = lazy(() => import('../components/mintnft'))
const StepOne = lazy(() => import('../components/wizard/stepone'))
const StepTwo = lazy(() => import('../components/wizard/steptwo'))
const StepThree = lazy(() => import('../components/wizard/stepthree'))
const Nft = lazy(() => import('../components/viewnft'))
const Collection = lazy(() => import('../components/viewcollection'))
const Terms = lazy(() => import('../components/terms'))
const Collections = lazy(() => import('../components/collections'))
const IndexedCollection = lazy(() => import('../components/ViewIndexedCollection'))
const CreateSeries = lazy(() => import('../components/series/createseries'))
const NftNy = lazy(() => import('../components/nftny'))
const MintNftSeries = lazy(() => import('../components/series/mintnftseries'))
const NftSeriesDetail = lazy(() => import('../components/series/nftseriesdetail'))
const Status = lazy(() => import('../components/status'))

export const globalRoutes = [
    {
        path: '',
        element: <Landing />,
        exact: true,
        restricted: false
    },
    {
        path: "home",
        element: <Landing />,
        exect: true,
        restricted: false
    }
]

export const publicRoutes = (wallet) => [
    {
        path: "/login",
        element: <Login wallet={wallet} />,
        restricted: false
    },
    {
        path: "/signup",
        element: <Signup wallet={wallet} />,
        restricted: false
    },
    {
        path: "/resetPassword",
        element: <ResetPassword />,
        restricted: false
    }
]

export const privateRoutes = (contractX, currentUser, nearConfig, wallet, account, near) => [
    {
        path: 'marketplace',
        element: <MarketPlace contractX={contractX}
            currentUser={currentUser}
            account={account}
            wallet={wallet}
            nearConfig={nearConfig} />,
        exact: true,
        restricted: true
    },
    {
        path: 'nftnyc',
        element: <NftNy />,
        exact: true,
        restricted: true
    },
    {
        path: 'about',
        element: <About />,
        exact: true,
        restricted: true
    },
    {
        path: 'terms',
        element: <Terms />,
        exact: true,
        restricted: true
    },
    {
        path: 'artists',
        element: <Artists />,
        exact: true,
        restricted: true
    },
    {
        path: 'drops',
        element: <Drops />,
        exact: true,
        restricted: true
    },
    {
        path: 'user/:userId/*',
        element: <Profile
            contractX={contractX}
            currentUser={currentUser}
            account={account}
            wallet={wallet}
            nearConfig={nearConfig}
        />,
        exact: true,
        restricted: true
    },
    {
        path: 'editprofile',
        element: <EditProfile
            contractX={contractX}
            currentUser={currentUser}
            account={account}
            wallet={wallet}
            nearConfig={nearConfig}
        />,
        exact: true,
        restricted: true
    },
    {
        path: 'createcollection',
        element: <CreateCollection
            contractX={contractX}
            currentUser={currentUser}
            account={account}
            wallet={wallet}
            nearConfig={nearConfig}
        />,
        exact: true,
        restricted: true
    },
    {
        path: 'mintnft',
        element: <MintNft
            contractX={contractX}
            currentUser={currentUser}
            account={account}
            wallet={wallet}
            near={near}
            nearConfig={nearConfig}
        />,
        exact: true,
        restricted: true
    },
    {
        path: 'series/createseries',
        element: <CreateSeries
            wallet={wallet}
        />,
        exact: true,
        restricted: true
    },
    {
        path: 'series/mintnftseries/:collectionId/:seriesId',
        element: <MintNftSeries
            wallet={wallet}
        />,
        exact: true,
        restricted: true
    },
    {
        path: 'series/detail/:collectionId/:seriesId',
        element: <NftSeriesDetail
            wallet={wallet}
        />,
        exact: true,
        restricted: true
    },
    {
        path: 'series/detail/:collectionId/:seriesId/:copyIndex',
        element: <NftNy
            wallet={wallet}
        />,
        exact: true,
        restricted: true
    },
    {
        path: 'collections',
        element: <Collections />,
        exact: true,
        restricted: true
    },
    {
        path: 'collections/:collectionId/*',
        element: <Collection
            contractX={contractX}
            currentUser={currentUser}
            account={account}
            wallet={wallet}
            nearConfig={nearConfig}
        />,
        exact: true,
        restricted: true
    },
    {
        path: 'status',
        element: <Status
            contractX={contractX}
            currentUser={currentUser}
            account={account}
            wallet={wallet}
            nearConfig={nearConfig}
        />,
        exact: true,
        restricted: true
    },
    {
        path: 'indexedcollections/:collectionId',
        element: <IndexedCollection
            contractX={contractX}
            currentUser={currentUser}
            account={account}
            wallet={wallet}
            nearConfig={nearConfig}
        />,
        exact: true,
        restricted: true
    },
    {
        path: 'nft/:collectionId/:tokenId',
        element: <Nft
            wallet={wallet}
        />,
        exact: true,
        restricted: true
    },
    {
        path: 'wizard/stepone',
        element: <StepOne
            contractX={contractX}
            account={account}
            wallet={wallet}
            nearConfig={nearConfig}
        />,
        exact: true,
        restricted: true
    },
    {
        path: 'claim-link',
        element: <StepTwo
            wallet={wallet}
        />,
        exact: true,
        restricted: true
    },
    {
        path: 'wizard/stepthree',
        element: <StepThree
            contractX={contractX}
            account={account}
            wallet={wallet}
            nearConfig={nearConfig}
        />,
        exact: true,
        restricted: true
    }

];