import React from "react";

const Logo = () =>{
    return(
        <>
            <svg width="91" height="17" viewBox="0 0 91 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.861328 12.7443V0.449838H5.45011C6.80076 0.449838 7.92631 0.726896 8.82675 1.28101C9.73874 1.82359 10.4256 2.56241 10.8874 3.49748C11.3491 4.42101 11.58 5.44843 11.58 6.57975C11.58 7.82652 11.326 8.91166 10.8181 9.83519C10.3102 10.7587 9.59444 11.4744 8.67091 11.9824C7.75892 12.4903 6.68532 12.7443 5.45011 12.7443H0.861328ZM8.68822 6.57975C8.68822 5.86402 8.56124 5.23487 8.30727 4.69229C8.0533 4.13818 7.68389 3.71105 7.19904 3.4109C6.71418 3.09921 6.13121 2.94336 5.45011 2.94336H3.70118V10.2508H5.45011C6.14275 10.2508 6.72573 10.0949 7.19904 9.78324C7.68389 9.46 8.0533 9.02133 8.30727 8.46721C8.56124 7.90155 8.68822 7.2724 8.68822 6.57975Z" fill="white"/>
                <path d="M18.7301 6.02564C18.0605 6.02564 17.4545 6.1353 16.9119 6.35464C16.3693 6.56244 15.9768 6.87413 15.7344 7.28971V12.7443H12.9638V3.65332H15.5093V5.48884C15.821 4.877 16.225 4.39792 16.7214 4.0516C17.2178 3.70527 17.7373 3.52634 18.2799 3.5148C18.4069 3.5148 18.4992 3.5148 18.5569 3.5148C18.6262 3.5148 18.6839 3.52057 18.7301 3.53211V6.02564Z" fill="white"/>
                <path d="M19.258 10.0257C19.258 9.42537 19.4254 8.90012 19.7601 8.4499C20.0949 7.99968 20.5567 7.64758 21.1454 7.39361C21.7342 7.1281 22.4095 6.99534 23.1714 6.99534C23.5408 6.99534 23.9045 7.02997 24.2623 7.09924C24.6202 7.15696 24.9319 7.24354 25.1974 7.35898V6.96071C25.1974 6.46431 25.0473 6.08336 24.7472 5.81784C24.447 5.55233 23.991 5.41957 23.3792 5.41957C22.8713 5.41957 22.3922 5.50615 21.942 5.67931C21.5033 5.85248 21.0358 6.10067 20.5394 6.42391L19.7082 4.65766C20.3085 4.26516 20.9319 3.97079 21.5783 3.77454C22.2363 3.57829 22.929 3.48016 23.6563 3.48016C25.0185 3.48016 26.0748 3.80917 26.8251 4.46718C27.587 5.11365 27.968 6.0545 27.968 7.28971V9.66203C27.968 9.93909 28.0084 10.1353 28.0892 10.2508C28.1816 10.3547 28.3374 10.4182 28.5567 10.4413V12.7443C28.3259 12.7905 28.1065 12.8251 27.8987 12.8482C27.7025 12.8713 27.5293 12.8828 27.3792 12.8828C26.8598 12.8828 26.4615 12.7789 26.1844 12.5711C25.9189 12.3633 25.7515 12.0805 25.6823 11.7226L25.6303 11.3244C25.2263 11.8439 24.7414 12.2421 24.1758 12.5192C23.6216 12.7847 23.0387 12.9175 22.4268 12.9175C21.8265 12.9175 21.284 12.7905 20.7991 12.5365C20.3258 12.2825 19.9506 11.9362 19.6736 11.4975C19.3965 11.0589 19.258 10.5682 19.258 10.0257ZM24.7645 10.372C24.8915 10.2681 24.9954 10.1526 25.0762 10.0257C25.157 9.89868 25.1974 9.77747 25.1974 9.66203V8.84817C24.9665 8.75581 24.7068 8.68655 24.4182 8.64037C24.1411 8.58265 23.8814 8.55379 23.639 8.55379C23.131 8.55379 22.7097 8.66923 22.3749 8.90012C22.0401 9.11945 21.8727 9.41383 21.8727 9.78324C21.8727 9.99103 21.9304 10.1815 22.0459 10.3547C22.1613 10.5278 22.3172 10.6664 22.5134 10.7703C22.7212 10.8742 22.9636 10.9261 23.2407 10.9261C23.5177 10.9261 23.7948 10.8742 24.0719 10.7703C24.3489 10.6664 24.5798 10.5336 24.7645 10.372Z" fill="white"/>
                <path d="M40.824 3.65332H43.4561L39.6985 12.7443H37.4127L36.0274 9.03864L34.6421 12.7443H32.3564L28.5988 3.65332H31.2135L33.6551 10.1296L34.5209 7.60141L32.8932 3.65332H35.1616L36.0274 6.38927L36.8932 3.65332H39.1443L37.5339 7.60141L38.3997 10.1296L40.824 3.65332Z" fill="white"/>
                <path d="M47.5931 12.9175C46.8197 12.9175 46.0635 12.7905 45.3247 12.5365C44.5859 12.2825 43.9625 11.9074 43.4546 11.411L44.407 9.66203C44.9611 10.0661 45.4979 10.372 46.0174 10.5798C46.5484 10.7876 47.0448 10.8915 47.5065 10.8915C47.8644 10.8915 48.1415 10.8338 48.3377 10.7183C48.534 10.6029 48.6321 10.4239 48.6321 10.1815C48.6321 10.0083 48.5744 9.87559 48.4589 9.78324C48.3435 9.67934 48.1646 9.58699 47.9221 9.50618C47.6912 9.42537 47.4026 9.33879 47.0563 9.24644C46.3637 9.01556 45.7865 8.79045 45.3247 8.57111C44.8629 8.35177 44.5166 8.08626 44.2857 7.77457C44.0549 7.45133 43.9394 7.03574 43.9394 6.5278C43.9394 5.91597 44.0895 5.38494 44.3896 4.93472C44.7013 4.47296 45.14 4.11509 45.7057 3.86112C46.2713 3.60715 46.9467 3.48016 47.7317 3.48016C48.355 3.48016 48.9611 3.57829 49.5499 3.77454C50.1501 3.97079 50.71 4.29979 51.2295 4.76156L50.1559 6.49317C49.6364 6.1353 49.1747 5.87556 48.7706 5.71395C48.3666 5.55233 47.9741 5.47152 47.5931 5.47152C47.3969 5.47152 47.2179 5.49461 47.0563 5.54078C46.8947 5.58696 46.762 5.66777 46.6581 5.78321C46.5657 5.88711 46.5195 6.02564 46.5195 6.1988C46.5195 6.37196 46.5657 6.51049 46.6581 6.61438C46.762 6.71828 46.9178 6.81063 47.1256 6.89144C47.3449 6.96071 47.6162 7.04729 47.9394 7.15119C48.6898 7.37052 49.3132 7.59563 49.8096 7.82652C50.306 8.04585 50.6754 8.32291 50.9178 8.65769C51.1718 8.98092 51.2988 9.41383 51.2988 9.9564C51.2988 10.8799 50.964 11.6072 50.2944 12.1382C49.6364 12.6577 48.736 12.9175 47.5931 12.9175Z" fill="white"/>
                <path d="M58.5398 12.2594C58.2858 12.3633 58.003 12.4672 57.6913 12.5711C57.3911 12.675 57.0737 12.7558 56.7389 12.8136C56.4041 12.8828 56.0751 12.9175 55.7519 12.9175C55.2901 12.9175 54.863 12.8424 54.4705 12.6924C54.0895 12.5307 53.7836 12.271 53.5527 11.9131C53.3334 11.5553 53.2237 11.082 53.2237 10.4932V5.74858H52.0635V3.65332H53.2237V0.761529H55.9943V3.65332H57.8471V5.74858H55.9943V9.54081C55.9943 9.84096 56.0693 10.0603 56.2194 10.1988C56.3695 10.3258 56.56 10.3893 56.7908 10.3893C56.9871 10.3893 57.1949 10.3547 57.4142 10.2854C57.6451 10.2161 57.8414 10.1411 58.003 10.0603L58.5398 12.2594Z" fill="white"/>
                <path d="M65.3856 6.02564C64.716 6.02564 64.11 6.1353 63.5674 6.35464C63.0248 6.56244 62.6323 6.87413 62.3899 7.28971V12.7443H59.6193V3.65332H62.1648V5.48884C62.4765 4.877 62.8805 4.39792 63.3769 4.0516C63.8733 3.70527 64.3928 3.52634 64.9354 3.5148C65.0624 3.5148 65.1547 3.5148 65.2124 3.5148C65.2817 3.5148 65.3394 3.52057 65.3856 3.53211V6.02564Z" fill="white"/>
                <path d="M66.5525 12.7443V3.65332H69.3231V12.7443H66.5525ZM66.5525 2.61436V0.103516H69.3231V2.61436H66.5525Z" fill="white"/>
                <path d="M79.9817 12.7443H77.2112V7.63604C77.2112 7.03574 77.0957 6.60284 76.8648 6.33733C76.6455 6.06027 76.3511 5.92174 75.9817 5.92174C75.7278 5.92174 75.468 5.991 75.2025 6.12953C74.937 6.26806 74.6945 6.45854 74.4752 6.70097C74.2674 6.94339 74.1058 7.22045 73.9904 7.53214V12.7443H71.2198V3.65332H73.7133V5.17715C73.9442 4.81928 74.2328 4.51336 74.5791 4.25939C74.9254 4.00542 75.3237 3.81494 75.7739 3.68796C76.2242 3.54943 76.7148 3.48016 77.2458 3.48016C77.8345 3.48016 78.3079 3.58983 78.6657 3.80917C79.0351 4.01696 79.3122 4.29402 79.4969 4.64035C79.6931 4.98667 79.8201 5.36185 79.8779 5.76589C79.9471 6.15839 79.9817 6.54512 79.9817 6.92608V12.7443Z" fill="white"/>
                <path d="M85.355 12.7789C84.7547 12.7789 84.2064 12.6635 83.71 12.4326C83.2136 12.1902 82.7865 11.8612 82.4286 11.4456C82.0823 11.0185 81.811 10.5278 81.6147 9.97372C81.4185 9.4196 81.3204 8.82508 81.3204 8.19015C81.3204 7.5206 81.4243 6.90299 81.6321 6.33733C81.8399 5.77167 82.1285 5.27527 82.4979 4.84814C82.8788 4.40946 83.3233 4.07468 83.8312 3.8438C84.3507 3.60138 84.9221 3.48016 85.5455 3.48016C86.2266 3.48016 86.8269 3.63024 87.3464 3.93038C87.8659 4.21899 88.2815 4.62303 88.5931 5.14251V3.65332H91.0001V12.1209C91.0001 13.0444 90.775 13.841 90.3248 14.5105C89.8861 15.1801 89.2685 15.6938 88.4719 16.0517C87.6869 16.4211 86.7749 16.6058 85.736 16.6058C84.7663 16.6058 83.9293 16.4442 83.2251 16.1209C82.5325 15.8093 81.9264 15.3648 81.4069 14.7876L82.8961 13.2811C83.2309 13.6621 83.6465 13.9622 84.1429 14.1815C84.6393 14.4124 85.1703 14.5279 85.736 14.5279C86.1862 14.5279 86.6018 14.4471 86.9827 14.2854C87.3637 14.1238 87.6638 13.8641 87.8832 13.5062C88.1141 13.1483 88.2295 12.6866 88.2295 12.1209V11.2378C87.9525 11.7342 87.5484 12.1151 87.0174 12.3807C86.4979 12.6462 85.9438 12.7789 85.355 12.7789ZM86.3074 10.5798C86.5268 10.5798 86.7288 10.5509 86.9135 10.4932C87.1097 10.4239 87.2887 10.3374 87.4503 10.2335C87.6119 10.118 87.762 9.98526 87.9005 9.83519C88.039 9.67357 88.1487 9.50041 88.2295 9.3157V7.41093C88.1141 7.08769 87.9467 6.81063 87.7273 6.57975C87.508 6.33733 87.2656 6.15262 87.0001 6.02564C86.7346 5.89865 86.4575 5.83516 86.1689 5.83516C85.8803 5.83516 85.609 5.89865 85.355 6.02564C85.1011 6.15262 84.8817 6.33733 84.697 6.57975C84.5239 6.81063 84.3853 7.07038 84.2814 7.35898C84.1891 7.64758 84.1429 7.94773 84.1429 8.25942C84.1429 8.5942 84.1948 8.90012 84.2987 9.17717C84.4142 9.45423 84.57 9.70243 84.7663 9.92177C84.9625 10.1296 85.1934 10.2912 85.4589 10.4066C85.7244 10.5221 86.0073 10.5798 86.3074 10.5798Z" fill="white"/>
                </svg>

        </>
    )
}

export default Logo;