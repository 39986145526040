import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../node_modules/locomotive-scroll/dist/locomotive-scroll.min.css'
import React, { Suspense } from 'react';
import { Loader } from './services/ui';
import { UserProvider } from './contexts/usercontext/usercontext';
import PrivateRoute from './routes/PrivateRoute';
import { privateRoutes, publicRoutes, globalRoutes } from './routes/routes';
import GlobalRoutes from './routes/GlobalRoute';

function App({ contractX, currentUser, nearConfig, wallet, account, near }: any) {

  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <>
          <ToastContainer />
          <UserProvider>
          <Routes>
            <Route element={<PrivateRoute currentUser={currentUser}
              nearConfig={nearConfig}
              wallet={wallet} />}>
              {
                privateRoutes(contractX, currentUser, nearConfig, wallet, account, near).map((route: any, key: any) => {
                  return <Route key={key} nearConfig={nearConfig} {...route} />
                })
              }
            </Route>
            <Route element={<GlobalRoutes currentUser={currentUser}
              nearConfig={nearConfig}
              wallet={wallet} />}>
              {
                globalRoutes.map((route: any, key: any) => {
                  return <Route key={key} nearConfig={nearConfig} {...route} />
                })
              }
            </Route>
            {
              publicRoutes(wallet).map((route: any, key: any) => {
                return <Route key={key} {...route} />
              })
            }
          </Routes>
        </UserProvider>
          </>
      </Router>
    </Suspense>
  );
}

export default App;
