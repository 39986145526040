import React, { useContext, useEffect, useState, useLayoutEffect } from "react";
import { NavLink, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import '../../styles/header.css';
import search from '../../images/header/search.svg';
import { Dropdown, Button, Modal } from 'react-bootstrap'
// import logo from '../../images/header/logo.png';
import logo from '../../imgs/full_logo.svg';
import logo_icon from '../../imgs/logo_icon.svg';
import headerlogo from '../../images/header/headerlogo.svg'
import Logo from "./logo";

import icon1 from '../../images/header/icon1.png'
import icon2 from '../../images/header/icon2.png';
import icon3 from '../../images/header/icon3.png';
import link1 from '../../images/header/link1.svg'
import link2 from '../../images/header/link2.svg';
import link3 from '../../images/header/link3.svg';
import link4 from '../../images/header/link4.svg';
import music from '../../imgs/music.svg';
import plus from '../../imgs/plus_white.svg';


import { NearContext } from '../../contexts';
import { getUser, getUserForUpdateDb } from "../../db/mongodb";
import { balance } from "../../services/utils";
import avtar from '../../images/users/avatar.svg';
import collection1 from '../../images/collection/collection1.svg';
import { ProfileModel, Profiles } from "../../models/profile";
import { Nft, Nfts } from "../../models/nft";
import { Collection } from "../../models/collection";
import { UserContext } from "../../contexts/usercontext/usercontext";
import LocomotiveScroll from 'locomotive-scroll';

export default function Header({ wallet }: any) {
  const [darkMode, setDarkMode] = React.useState(true);

  const { authUser, fetchUser, logOutUser }: any = useContext(UserContext);
  console.log("authUser ", authUser);

  const { signIn, signOut } = useContext(NearContext);

  const [profile, setProfile] = useState<ProfileModel>();
  const [searchString, setSearchBoxValue] = useState('');
  const [isMenuOpened, setMenuOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [show, setShow] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [headerColor, setHeaderColor] = useState("")

  const navigate = useNavigate();

  const location = useLocation();

  let available = balance();

  const user = wallet.getAccountId();
  let User = wallet.isSignedIn();

  const handleUser = async () => {

    if (User) {
      signOut()

    } else if (!User) {
      signIn()
    }
  }

  const listenScrollEvent = () => {
    window.scrollY > 30 ? setHeaderColor("#000000") : setHeaderColor("");
    // console.log("scrollY ", window.scrollY)
  }

  useEffect(() => {

    // const scroller = new LocomotiveScroll({
    //   el: document.querySelector('[data-scroll-container]'),
    //   smooth: true
    // })
    // scroller.on('scroll', ({ scroll }: any) => {
    //   scroll.y > 30 ? setHeaderColor("#000000") : setHeaderColor("");
    // })

    window.addEventListener("scroll", listenScrollEvent)

    setSearchBoxValue(searchParams.get("searchString") || '');
    getProfile();
  }, [user])

  const getProfile = async () => {
    if (user) {
      const muser = await getUserForUpdateDb();
      const pro: any = await muser.functions.get_profile(user);
      if (!pro) {

        await muser.functions.update_profile(
          user,
          "",
          "",
          "",
          "",
          user,
          "",
          "",
          "",
          "",
          "",
          "",
          ""
        );
      }

      (window as any).loggedInUser = pro;
      console.log(pro);
      setProfile(pro);
    }
  };

  const [windowWidth, setSize] = useState(0);
  useWindowSize()

  function useWindowSize() {
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
  }


  const [nfts, setNfts] = useState<Nft[]>([]);
  const [users, setUsers] = useState<ProfileModel[]>([]);
  const [collections, setCollections] = useState<Collection[]>([]);
  const [isLoading, setLoader] = useState(false);
  const [count, setCount] = useState(0);
  const [colCount, setColCount] = useState(0);
  const [userCount, setUserCount] = useState(0);

  const getAllNfts = async () => {
    // setLoader(true);
    const user = await getUser();
    const nftsResponse = await user.functions.search_listed_nfts_by_name(5, count * 5, searchString);
    console.log(nftsResponse);
    const allNfts: any[] = [];
    nftsResponse.forEach((nft: any) => {
      nft.contractId = nft.contract_id;

      allNfts.push(nft);
    })

    // setNfts([...nfts, ...allNfts]);

    setNfts((prevState) => [...prevState, ...allNfts])

    // setLoader(false);
  }

  const getCollections = async () => {
    // setLoader(true);
    const user = await getUser();
    const response = await user.functions.search_collections_by_name(5, colCount * 5, searchString);
    console.log(response);
    //setCollections([...collections, ...response]);
    setCollections((prevState) => [...prevState, ...response])
    // setLoader(false);
  }

  const getUsers = async () => {
    try {
      // setLoader(true);
      // const searchString = searchParams.get("searchString") || '';
      // setSearchString(searchString);
      const user = await getUserForUpdateDb();
      const response = await user.functions.search_profiles_by_name(5, userCount * 5, searchString);
      console.log("users ", response)
      //setUsers([...users, ...response]);
      setUsers((prevState) => [...prevState, ...response])
      // setLoader(false);
    } catch (error) {
      setLoader(false);
      setUsers([]);
      console.log(error);
    }
  }

  const gotoHome = async () => {
    navigate("/");
  }

  const searchValue = async () => {
    setLoader(true);

    setMenuOpen(true);
    var menu: any = document.getElementsByClassName('search-menu')[0].children[0].getElementsByClassName('dropdown-menu')[0];
    if (menu) {
      menu.style.display = "block";
    }


    setNfts([]);
    setCollections([]);
    setUsers([]);

    await getAllNfts();
    await getCollections();
    await getUsers();
    setLoader(false);
  }

  const handleKeyUp = (event: { key: string; }) => {
    if (event.key === 'Enter') {

      searchValue();

    }
  }

  const hideMenu = () => {
    setMenuOpen(false);
    var menu: any = document.getElementsByClassName('search-menu')[0].children[0].getElementsByClassName('dropdown-menu')[0];
    if (menu) {
      menu.style.display = "none";
    }
  }
  // setSearchBoxValue(searchParams.get("searchString") || '');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchBoxValue(
      e.target.value
    );
  };

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (!User) {
      handleUser()
    } else {
      setShow(true);
    }
  }

  return (
    <nav className="navbar sticky-top navbar-expand-lg navbar-light menu res-padding-right" id='header' style={{ "backgroundColor": headerColor }}>
      <div className="container-fluid px-lg-4">

        <NavLink to="/" className="navbar-brand animate__animated animate__heartBeat d-none d-lg-block d-xl-block"><span><img src={headerlogo} alt="logo" /><Logo /></span></NavLink>

        <button className="navbar-toggler desk-none d-block-992" type="button" onClick={() => {
          setExpanded(!expanded);
        }} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <button className="navbar-toggler desk-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="search-box mx-3 tab-none">
          <input type="text" placeholder="Collection, item or user"
            name="searchString"
            value={searchString}
            onChange={(e) => {
              handleChange(e);
            }}
            onKeyUp={handleKeyUp}
            required
          />
          <div className="search-menu" >
            <Dropdown align="end" onToggle={() => { setMenuOpen(!isMenuOpened) }} >
              <Dropdown.Toggle id="dropdown-button-dark" variant="dark" className="search-icon" onClick={() => { setMenuOpen(!isMenuOpened) }}>
                <img src={search} className="search-icon" onClick={searchValue} alt="search icon" />
              </Dropdown.Toggle>
              <Dropdown.Menu show={isMenuOpened} rootCloseEvent="click">
                {isLoading && (
                  <Dropdown.Item className="text-center justify-content-center" onClick={() => { hideMenu() }}>
                    Loading...
                  </Dropdown.Item>
                )}
                {!isLoading && (
                  <>
                    <Dropdown.Header >NFT(S) <a href="#!" onClick={() => {
                      hideMenu()
                    }}
                      className="float-end text-decoration-none color-theme"><b>X</b></a>
                    </Dropdown.Header>
                    <Dropdown.Divider />
                    {nfts && nfts.length > 0 && nfts.map((nft: any, index: number) => {
                      return (
                        <Dropdown.Item onClick={() => { hideMenu(); navigate(`/nft/${nft.contract_id}/${nft.id}`); }} key={index}>
                          <img src={nft.media_link ? nft.media_link.replace("ipfs.infura.io", "drawstring2.infura-ipfs.io") : collection1} width="42" height="42" className="border-radius-50" alt="nft media" /> {nft.name}
                        </Dropdown.Item>
                      )
                    })}
                    <Dropdown.Header >Collections</Dropdown.Header>
                    <Dropdown.Divider />
                    {collections && collections.length > 0 && collections.map((collection: any, index: number) => {
                      return (
                        <Dropdown.Item onClick={() => { hideMenu(); navigate(`/collections/${collection.contractId}`); }} key={index}>
                          <img src={collection.img ? collection.img.replace("ipfs.infura.io", "drawstring2.infura-ipfs.io") : collection1} width="42" height="42" className="border-radius-50" alt="nft media" /> {collection.name}
                        </Dropdown.Item>
                      )
                    })}
                    <Dropdown.Header>Profiles</Dropdown.Header>
                    <Dropdown.Divider />
                    {users && users.length > 0 && users.map((user: any, index: number) => {
                      return (
                        <Dropdown.Item onClick={() => { hideMenu(); navigate(`/user/${user.walletId}`); }} key={index}>
                          <img src={user.profile_pic.replace("ipfs.infura.io", "drawstring2.infura-ipfs.io") || avtar} width="42" height="42" className="border-radius-50" alt="user media" /> {user.display_name || user.walletId}
                        </Dropdown.Item>
                      )
                    })}
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>



        <div className="collapse navbar-collapse mobile-navbar-collapse" id="navbarSupportedContent" style={{ display: expanded ? 'block' : 'none' }}>
          <ul className="navbar-nav ms-auto my-2 mb-lg-0">
            <li>
              <div className="search-box d-xl-none d-lg-none d-md-block" >
                <input type="text" placeholder="Collection, item or user"
                  name="searchString" className="w-100 input-css"
                  value={searchString}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onKeyUp={handleKeyUp}
                  required
                />
                <div>
                  <img src={search} className="search-icon" onClick={searchValue} alt="search icon" />
                </div>
              </div>
            </li>

            <li className="nav-item">
              <NavLink to="/marketplace" className="nav-link">Marketplace</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={`/user/${wallet.getAccountId()}`} className="nav-link">Profile</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/about" onClick={(e) => { e.preventDefault(); navigate("/about") }} className="nav-link">About</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/artists" className="nav-link">Artists</NavLink>
            </li>
            <li className="nav-item d-xl-none d-lg-none d-md-block">
              <NavLink to="/drops" className="nav-link">
                Music Player
              </NavLink>
            </li>
          </ul>
        </div>

        <ul className="navbar-nav me-auto mb-2 mb-lg-0 create-signin-btn align-item">
          <li className="search-box d-lg-none d-xl-none d-md-block">
            <div className="search-menu mobile_serch-bar" >
              <Dropdown align="end" onToggle={() => { setExpanded(!expanded); }} >
                <Dropdown.Toggle id="dropdown-button-dark" variant="dark" className="" onClick={() => { setExpanded(!expanded) }}>

                  <img src={search} className="mobile-search-icon" alt="search icon" />
                </Dropdown.Toggle>
                <Dropdown.Menu show={isMenuOpened} rootCloseEvent="click" className="w-100">
                  {isLoading && (
                    <Dropdown.Item className="text-center justify-content-center" onClick={() => { hideMenu() }}>
                      Loading...
                    </Dropdown.Item>
                  )}
                  {!isLoading && (
                    <>
                      <Dropdown.Header >NFT(S) <a href="#!" onClick={() => {
                        hideMenu()
                      }}
                        className="float-end text-decoration-none color-theme"><b>X</b></a>
                      </Dropdown.Header>
                      <Dropdown.Divider />
                      {nfts && nfts.length > 0 && nfts.map((nft: any, index: number) => {
                        return (
                          <Dropdown.Item onClick={() => { hideMenu(); navigate(`/nft/${nft.contract_id}/${nft.id}`); }} key={index}>
                            <img src={nft.media_link ? nft.media_link.replace("ipfs.infura.io", "drawstring2.infura-ipfs.io") : collection1} width="42" height="42" className="border-radius-50" alt="nft media" /> {nft.name}
                          </Dropdown.Item>
                        )
                      })}
                      <Dropdown.Header >Collections</Dropdown.Header>
                      <Dropdown.Divider />
                      {collections && collections.length > 0 && collections.map((collection: any, index: number) => {
                        return (
                          <Dropdown.Item onClick={() => { hideMenu(); navigate(`/collections/${collection.contractId}`); }} key={index}>
                            <img src={collection.img ? collection.img.replace("ipfs.infura.io", "drawstring2.infura-ipfs.io") : collection1} width="42" height="42" className="border-radius-50" alt="nft media" /> {collection.name}
                          </Dropdown.Item>
                        )
                      })}
                      <Dropdown.Header>Profiles</Dropdown.Header>
                      <Dropdown.Divider />
                      {users && users.length > 0 && users.map((user: any, index: number) => {
                        return (
                          <Dropdown.Item onClick={() => { hideMenu(); navigate(`/user/${user.walletId}`); }} key={index}>
                            <img src={user.profile_pic.replace("ipfs.infura.io", "drawstring2.infura-ipfs.io") || avtar} width="42" height="42" className="border-radius-50" alt="user media" /> {user.display_name || user.walletId}
                          </Dropdown.Item>
                        )
                      })}
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </li>
          <li className="nav-item">
            <Button variant="dark" className="theame-btn create-1 create-1-mobile" onClick={(e) => { e.preventDefault(); navigate("/drops") }}>
              <img src={music} className="img-fluid d-lg-none d-xl-none d-md-block" width={20} /> <span className="d-none d-lg-block d-xl-block"> Music Player</span>
            </Button>
          </li>
          <li className="nav-item">
            <Button variant="dark" className="theame-btn create create-mobile" onClick={handleShow}>
              <img src={plus} className="img-fluid d-lg-none d-xl-none d-md-block" width={20} /> <span className="d-none d-lg-block d-xl-block"> Create</span>
            </Button>
            <Modal show={show} onHide={handleClose} size="xl" className="menu-modal" centered>
              <Modal.Body className="border-2-solid">
                <div className="d-flex properties-box-row create-menu text-center justify-content-center py-5 font-size-16 font-weight-700">
                  <div className="properties-box border-2-solid p-2 text-create m-2 w-h-200 d-flex justify-content-center align-items-center" onClick={(e) => { e.preventDefault(); navigate("/createcollection");; handleClose(); }}>
                    <div>
                      <div className="height-65 d-flex justify-content-center">
                        <img src={link1} alt="collection icon" />
                      </div>
                      <div className="pt-2">Create collection</div>
                    </div>
                  </div>
                  <div className="properties-box border-2-solid p-2 text-create m-2 w-h-200 d-flex justify-content-center align-items-center" onClick={(e) => { e.preventDefault(); navigate("/mintnft"); handleClose(); }}>
                    <div>
                      <div className="height-65">
                        <img src={link2} alt="nft icon" />
                      </div>
                      <div className="pt-2">Mint NFT</div>
                    </div>
                  </div>
                  <div className="properties-box border-2-solid p-2 text-create m-2 w-h-200 d-flex justify-content-center align-items-center">
                    <div>
                      <div className="height-65">
                        <img src={link3} alt="album icon" />
                      </div>
                      <div className="pt-2">Create an album</div>
                    </div>
                  </div>
                  <div className="properties-box border-2-solid p-2 text-create m-2 w-h-200 d-flex justify-content-center align-items-center" onClick={(e) => { e.preventDefault(); navigate("/claim-link"); handleClose(); }}>
                    <div>
                      <div className="height-65">
                        <img src={link4} alt="claim link icon" />
                      </div>
                      <div className="pt-2">Claim Links</div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </li>
          <li className="nav-item">
            {/* {!User && (
              <a
                href="#"
                onClick={(e: any) => {
                  e.preventDefault()
                  handleUser()
                }}
                className="login-link ms-2"
              >
                Login
              </a>
            )} */}
          </li>
        </ul>

        {/* {User && ( */}
        <Dropdown align="end">
          <Dropdown.Toggle variant="" id="dropdown-basic">
            <div
              className="btn toggle-link p-0"
            >
              <img src={(profile && profile.profile_pic) ? profile?.profile_pic.replace("ipfs.infura.io", "drawstring2.infura-ipfs.io") : avtar} width="44" height="44" className="border-radius-50" alt="profile media" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="d-menu font-size-14">
            {user && (
              <Dropdown.Item className="text-light font-weight-700 text-ellipsis"> {user}
                <div className="text-dorange">{parseFloat(available).toFixed(2)} NEAR</div>
              </Dropdown.Item>
            )}

            {!user && (
              <>
                <Dropdown.Item className="text-light font-weight-700 text-ellipsis">
                  {authUser?.profile?.email}
                </Dropdown.Item>

                <Dropdown.Item className="text-light font-weight-700 text-ellipsis" onClick={(e: any) => { e.preventDefault(); handleUser(); }}>
                  Connect Wallet
                </Dropdown.Item>
              </>
            )}

            <Dropdown.Divider className="text-light" />
            <Dropdown.Item className="text-light" onClick={(e) => { e.preventDefault(); navigate("/createcollection") }}>Create Collection</Dropdown.Item>
            <Dropdown.Item className="text-light" onClick={(e) => { e.preventDefault(); navigate("/mintnft") }}>Mint Nft</Dropdown.Item>
            <Dropdown.Item className="text-light" onClick={(e) => { e.preventDefault(); navigate(`/user/${wallet.getAccountId()}`); }}>My Profile</Dropdown.Item>
            <Dropdown.Item className="text-light" onClick={(e) => { e.preventDefault(); navigate("/editprofile") }}>Edit Profile</Dropdown.Item>
            <Dropdown.Divider className="text-light" />
            <Dropdown.Item className="text-light text-center" onClick={(e: any) => { e.preventDefault(); logOutUser(); signOut(); navigate("/login") }}><b>{authUser ? 'Sign out': 'Sign in'}</b></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {/* )} */}

      </div>
    </nav>
  )
}
