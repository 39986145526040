import React, { useReducer, useEffect } from 'react';

import { initialNearState, nearReducer } from './reducer';
import { CLEAR_STATE, LOADING_ERROR, LOADING_START, LOADING_SUCCESS, SET_USER } from './types';

export const NearContext = React.createContext({
  ...initialNearState,
  nearContent: null,
  signIn: () => { },
  signOut: () => { },
  startLoading: () => { },
});

export const NearContextProvider = ({ currentUser, nearConfig, wallet, near, children }: any) => {
  const [nearState, dispatchNear] = useReducer(nearReducer, initialNearState);

  const setUser = (user: any) => {
    dispatchNear({ type: SET_USER, payload: { user } });
  };

  const loadingStart = () => {
    dispatchNear({ type: LOADING_START });
  };

  const loadingSuccess = () => {
    dispatchNear({ type: LOADING_SUCCESS });
  };

  const loadingError = (error: string) => {
    dispatchNear({ type: LOADING_ERROR, payload: { error } });
  };

  const clearState = () => {
    console.log("test tttt")
    dispatchNear({ type: CLEAR_STATE });
  };

  const signIn = () => {
    wallet.requestSignIn(nearConfig.CONTRACT_NAME, `drawstring`, `${window.location.origin}/#/mint`);
  };
  const signOut = () => {
    console.log("logout triggered")
    wallet.signOut();

    clearState();

    //window.location.reload();
  };

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length) {
      setUser(currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    if (!nearState.user && !nearState.isLoading && !nearState.error) {
      loadingStart();
    }
  }, [nearState]);

  useEffect(() => {
    if (nearState.user && nearState.isLoading) {
      loadingSuccess();
    }
  }, [nearState]);

  useEffect(() => {
    // if (!nearState.user && !localStorage.getItem('undefined_wallet_auth_key') && !nearState.error) {
    //   localStorage.clear();
    //   loadingError('wallet not found');
    // }
  }, [nearState]);

  return (
    <NearContext.Provider
      value={{
        ...nearState,
        ...near,
        signIn,
        signOut,
      }}
    >
      {children}
    </NearContext.Provider>
  );
};


