import { useEffect, useRef } from 'react';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll';
import { Outlet, Navigate } from 'react-router-dom';
import Footer from '../components/global/footer';
import Header from '../components/global/header';

function GlobalRoutes({ currentUser, nearConfig, wallet }) {
    const containerRef = useRef();
    return <LocomotiveScrollProvider options={{ smooth: false, class: 'is-reveal' }} watch={[]} containerRef={containerRef}>
        <Header currentUser={currentUser}
            nearConfig={nearConfig}
            wallet={wallet} />
        <main data-scroll-container ref={containerRef}>
            <div className='main-content'>
                <Outlet wallet={wallet} />
            </div>
            <Footer />
        </main>
    </LocomotiveScrollProvider>

}

export default GlobalRoutes;