import { NavLink } from "react-router-dom";
import twitter from '../../imgs/twitter.svg'
import discord from '../../imgs/discordnew.svg'
import instagram from '../../imgs/insta.svg'
import footerlogo from '../../imgs/footer-logo.png'
import '../../styles/footer.css';
import { Button } from "react-bootstrap";
import { useForm } from 'react-hook-form';

function Footer({ wallet }: any) {

  const { register, handleSubmit, getValues, formState: { errors } } = useForm();
  const onSubmit = async (data: any) => {

  }

  return (
    <div>
      <footer className="menu text-white text-center text-lg-start footer-bg text-light">
        <div className="container p-4 px-0">
          <div className="row" >

            <div className="col-lg-3 mb-4 mb-md-0 pe-lg-5 footer-new">
              <a href="/"><img src={footerlogo} alt="Logo" /></a>
            </div>
            <div className="col-lg-5 mb-4 mb-md-0 pe-lg-5 footer-new">
              <h2>Subscribe for updates</h2> 
              <form onSubmit={handleSubmit(onSubmit)}>
                <input type="email" className="text-center" placeholder="Enter your e-mail" {...register("email", { required: true })} style={{ border: errors.email ? '1px solid #f22e62' : '' }} />
                <Button type='submit' variant="dark" className="theame-btn create text-capitalize px-5 mt-3 mobile-margin-0-auto">
                  Submit
                </Button>
              </form>
            </div>
            <div className="col-lg-2 mb-4 mb-md-0 pe-lg-5 footer-new">
              <h2>Marketplace</h2>
              <ul>
                <li><NavLink to="/" className="text-gray">Homepage</NavLink></li>
                <li><NavLink to="/marketplace" className="text-gray">Marketplace</NavLink></li>
                <li><NavLink to="/profile" className="text-gray">Profile</NavLink></li>
                <li><NavLink to="/about" className="text-gray">About us</NavLink></li>
                <li><NavLink to="/artists" className="text-gray">Artists</NavLink></li>
              </ul>
            </div>
            <div className="col-lg-2 mb-4 mb-md-0 pe-lg-5 footer-new ">

              <h2>Follow us</h2>
              <a className="text-white footer-links" href="https://twitter.com/drawstring_nft"><img src={twitter} width="24" height="24" alt="twitter" /></a>
              <a className="text-white footer-links m-3" href="https://www.instagram.com/drawstring_nft/"><img src={instagram} width="24" height="24" alt="instagram" /></a>
              <a className="text-white footer-links" href="https://discord.gg/kKcg3r8djc"><img src={discord} width="24" height="24" alt="discord" /></a>
            </div>

            {/* <div className="col-lg-5 col-md-6 mb-4 mb-md-0 pe-lg-5">
              <NavLink to="/" className="navbar-brand text-light text-transform-uppercase font-size-18"><img className="logo" src={logo_icon} alt="" /></NavLink>
              <div className="slide-dots">
                <div><a className="text-white footer-links" href="https://twitter.com/drawstring_nft"><img src={twitter} width="30" height="30" alt="twitter" /></a></div>
                <div><a className="text-white footer-links" href="https://www.instagram.com/drawstring_nft/"><img src={instagram} width="30" height="30" alt="instagram" /></a></div>
                <div><a className="text-white footer-links" href="https://discord.gg/kKcg3r8djc"><img src={discord} width="30" height="30" alt="discord" /></a></div>
              </div>
            </div>*/}
            {/* <div className="col-lg-7 col-md-6"> 
              <div className="row">
              

                <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                  <h5 onClick={() => { navigate('/marketplace') }} className="footer-link-title discover mb-3 text-light">Discover</h5>
                </div>
                <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                  <h5 className="footer-link-title mb-3"><NavLink to={`/user/${wallet.getAccountId()}`} className="text-white footer-links">My Profile</NavLink></h5>
                  <ul className="list-unstyled">
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                  <h5 className="footer-link-title mb-3"><NavLink to="/about" className="text-white footer-links">About</NavLink></h5>
                  <ul className="list-unstyled">
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                  <h5 className="footer-link-title mb-3">
                    <a className="text-white footer-links" href="mailto:info@drawstring.io">Contact Us</a>
                  </h5>
                </div>
              </div>
            </div> */}
          </div>
          <div className="row footer-btm">
            <div className="col-sm-6 font-size-14 font-weight-500">
              © Drawstring, Inc. All rights reserved.
            </div>
            <div className="col-sm-6 text-lg-end font-size-14 font-weight-500">
              <NavLink to="/terms" className="text-gray">Terms of Service</NavLink>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
