import { createContext, useEffect, useState } from "react";
import { App, Credentials } from "realm-web";
import { APP_ID } from "../../Constants/realm/constants";

// Creating a Realm App Instance
const app: any = new App(APP_ID);

// Creating a user context to manage and access all the user related functions
// across different component and pages.
export const UserContext = createContext({});

export const UserProvider = ({ children }: any) => {
    const [authUser, setUser] = useState(null);

    // Function to login user into our Realm using their email & password
    const emailPasswordLogin = async (email: any, password: any) => {
        const credentials = Credentials.emailPassword(email, password);
        const authedUser: any = await app.logIn(credentials);
        setUser(authedUser);
        return authedUser;
    };

    // Function to signup user into our Realm using their email & password
    const emailPasswordSignup = async (email: any, password: any) => {
        try {
            return await app.emailPasswordAuth.registerUser(email, password);
            // Since we are automatically confirming our users we are going to login
            // the user using the same credentials once the signup is complete.
            //return emailPasswordLogin(email, password);
        } catch (error) {
            throw error;
        }
    };

    // Function to fetch-user(if the user is already logged in) from local storage
    const fetchUser = async () => {
        if (!app.currentUser) return false;
        try {
            await app.currentUser.refreshCustomData();
            // Now if we have a user we are setting it to our user context
            // so that we can use it in our app across different components.
            setUser(app.currentUser);
            return app.currentUser;
        } catch (error) {
            throw error;
        }
    }

    const confirmUser = async (token: string, tokenId: string) => {
        try {
            return await app.emailPasswordAuth.confirmUser(token, tokenId);
        } catch (error) {
            throw error;
        }
    }

    const resendConfirmationEmail = async (email: string) => {
        try {
            return await app.emailPasswordAuth.resendConfirmationEmail(email);
        } catch (error) {
            throw error;
        }
    }

    // Function to logout user from our Realm
    const logOutUser = async () => {
        if (!app.currentUser) return false;
        try {
            await app.currentUser.logOut();
            // Setting the user to null once loggedOut.
            setUser(null);
            return true;
        } catch (error) {
            throw error
        }
    }

    const sendEmailResetPassword = async (email: string) => {
        try {
            return await app.emailPasswordAuth.sendResetPasswordEmail(email);
        } catch (error) {
            throw error;
        }
    }

    const resetConfirm = async (password: string, token: string, tokenId: string) => {
        try {
            return await app.emailPasswordAuth.resetPassword(token, tokenId, password);
        } catch (error) {
            throw error;
        }
    }

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    return <UserContext.Provider value={{ authUser, setUser, fetchUser, emailPasswordLogin, emailPasswordSignup, logOutUser, confirmUser, resendConfirmationEmail, sendEmailResetPassword, resetConfirm}}>
        {children}
    </UserContext.Provider>;
}
