
const networkId = process.env.REACT_APP_NETWORKID;
const CONTRACT_NAME = process.env.REACT_APP_CONTRACTNAME;

export default function getConfig() {
	const config = {
		networkId: networkId,
		explorerUrl: `https://explorer.${networkId === 'mainnet' ? '' : networkId + '.'}near.org`,
		nodeUrl: `https://rpc.${networkId}.near.org`,
		rpcUrl: `https://archival-rpc.${networkId}.near.org`,
		contractName: CONTRACT_NAME,
		walletUrl: `https://wallet.${networkId === 'mainnet' ? '' : networkId + '.'}near.org`,
		helperUrl: `https://helper.${networkId}.near.org`,
	};
	return config;
}
