import { useEffect, useRef } from 'react';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll';
import { Outlet, Navigate } from 'react-router-dom';
import Footer from '../components/global/footer';
import Header from '../components/global/header';

function PrivateRoute({ currentUser, nearConfig, wallet }) {
    const isAuthourized = true;//localStorage.getItem("mongoUser");
    const containerRef = useRef();

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return isAuthourized ?
        <>
            <LocomotiveScrollProvider options={{ smooth: false, class: 'is-reveal' }} watch={[]} containerRef={containerRef}>
                <Header currentUser={currentUser}
                    nearConfig={nearConfig}
                    wallet={wallet} />
                <main data-scroll-container ref={containerRef}>
                    <div className='main-content'>
                        <Outlet wallet={wallet} />
                    </div>
                    <Footer />
                </main>
            </LocomotiveScrollProvider>

        </>
        : <Navigate to="/" />;

}

export default PrivateRoute;
