import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {
  NearContextProvider,
  NftContractContextProvider,
} from './contexts'
import { initContracts } from './services/utils'

import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en.json'
import ru from 'javascript-time-ago/locale/ru.json'
import ModalProvider from 'mui-modal-provider';

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

(window as any).nearInitPromise = initContracts().then(
  ({
    contractX,
    currentUser,
    nearConfig,
    walletConnection,
    account,
    nearAPI,
    nftContract,
    marketContract,
    near,
  }: any) => {
    
    root.render(
      // <React.StrictMode>
      <React.Fragment>
        {/* <App /> */}
        <NearContextProvider
          currentUser={currentUser}
          nearConfig={nearConfig}
          wallet={walletConnection}
          near={near}
        >
          <NftContractContextProvider nftContract={nftContract}>
          <ModalProvider>

            <App
              contractX={contractX}
              account={account}
              nearAPI={nearAPI}
              contract={nftContract}
              wallet={walletConnection}
            />
            </ModalProvider>
          </NftContractContextProvider>
        </NearContextProvider>
        </React.Fragment>
      // </React.StrictMode>
      
    );
  })

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
