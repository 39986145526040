import { connect, Contract, keyStores, WalletConnection } from 'near-api-js';
import getConfig from '../config';
import { formatNearAmount } from 'near-api-js/lib/utils/format';
import { nftMethods, marketMethods } from '../Constants/contractMethods';
const APP_NAME = 'Drawstring'
let available: any;
let account: any;
console.log("process.env.NODE_ENV => ", process.env.NODE_ENV);

console.log('REACT_APP_ENVIRONMENT => ', process.env.REACT_APP_ENVIRONMENT);

const nearConfig: any = getConfig();
export const marketContractName: any = process.env.REACT_APP_MARKETCONTRACTNAME; //apiConfig[process.env.NODE_ENV].marketContractName;
export const smartContractName: any = process.env.REACT_APP_SMARTCONTRACTNAME; //apiConfig[process.env.NODE_ENV].smartContractName;

// Initialize contract & set global variables
export async function initContracts() {
    // Initialize connection to the NEAR testnet
    const near = await connect({ deps: { keyStore: new keyStores.BrowserLocalStorageKeyStore() }, ...nearConfig });

    // Initializing Wallet based Account. It can work with NEAR testnet wallet that
    // is hosted at https://wallet.testnet.near.org
    const walletConnection: any = new WalletConnection(near, null);

    // Load in account data
    let currentUser;
    if (walletConnection.isSignedIn()) {
        currentUser = {
            accountId: walletConnection.getAccountId(),

        };
        const near = await connect({ deps: { keyStore: new keyStores.BrowserLocalStorageKeyStore() }, ...nearConfig });
        const account = await near.account(currentUser.accountId);
        const balance = await account.getAccountBalance()
        available = formatNearAmount(balance.available);
    } else {
        currentUser = {
            //// live
            // accountId: "drawstringmarketplace.drawstring_v2.near",//'stingy.testnet',

            //// dev
            // accountId: smartContractName

            accountId: process.env.REACT_APP_ACCOUNTID //apiConfig[process.env.NODE_ENV].accountId
        };
    }
  // account = await near.account(currentUser.accountId);
   const accountID = walletConnection.getAccountId();
   account = await near.account(accountID)

    const contractX = await new Contract(
        walletConnection.account(),
        //nearConfig.CONTRACT_NAME,
        smartContractName,
        {
            changeMethods: ["deploy_contract_code", "deploy_nftdrop_contract"],
            viewMethods: []
        }
    );

    // Initializing our contract APIs by contract name and configuration
    const nftContract = await new Contract(walletConnection.account(), 'manmade.drawstringnft.testnet', {
        // View methods are read only. They don't modify the state, but usually return some value.
        viewMethods: [...nftMethods.viewMethods],
        // Change methods can modify the state. But you don't receive the returned value when called.
        changeMethods: [...nftMethods.changeMethods],
        // Sender is the account ID to initialize transactions.
        //sender: walletConnection.getAccountId(),
    });

    // Initializing our contract APIs by contract name and configuration
    const marketContract = await new Contract(
        walletConnection.account(),
        marketContractName,
        {
            // View methods are read only. They don't modify the state, but usually return some value.
            viewMethods: [...marketMethods.viewMethods],
            // Change methods can modify the state. But you don't receive the returned value when called.
            changeMethods: [...marketMethods.changeMethods],
            // Sender is the account ID to initialize transactions.
            // sender: walletConnection.getAccountId(),
        }
    );

    return {
        nftContract,
        marketContract,
        currentUser,
        nearConfig,
        walletConnection,
        near,
        contractX,
        account,
    };
}
export function balance() {
    return available;
};
export function signer() {
    return account;
};
export function logout() {
    console.log("logout triggered");

    (window as any).walletConnection.signOut();
    // reload page
    window.location.replace(window.location.origin + window.location.pathname);
}

export function login() {
    // Allow the current app to make calls to the specified contract on the
    // user's behalf.
    // This works by creating a new access key for the user's account and storing
    // the private key in localStorage.
    
    console.log(nearConfig.contractName, 'conname');
    (window as any).walletConnection.requestSignIn(nearConfig.contractName, APP_NAME);
}