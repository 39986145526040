import * as Realm from "realm-web";

const APP_ID = "drawstringrealmapp-vafye";
const APP_ID1 = "drawstringwriteapp-gwzcp";
const APP_ID_TESTNET = "drawstring-acpul";

const app: any = new Realm.App({ id: APP_ID });
const app1: any = new Realm.App({ id: APP_ID1 });

const CLUSTER_NAME = "mongodb-atlas";
const DATABASE_NAME = "DrawstringMarketplace";

//getValidAccessToken();

var mongo;
var mongodb: any;

// var app1: any;
// var apiKey = "TlNAc6GuyjKmHR2r1010Uyv8w6xK0G3pjHkVW0u97ZA0efQMTH9KR3sO9WOvJ2eT";
// if (process.env.REACT_APP_NETWORKID === "testnet") {
//     apiKey = "Qx5I4lQpfHWCP9xi9VlkwVVy58HR3fLzSZMLhzaRoHIbxQosmvQ4vtXAetHmQw8o";
//     app1 = new Realm.App({ id: APP_ID_TESTNET });
// } else {
//     app1 = new Realm.App({ id: APP_ID1 });
// }

const apiKey = "TlNAc6GuyjKmHR2r1010Uyv8w6xK0G3pjHkVW0u97ZA0efQMTH9KR3sO9WOvJ2eT";

const getUserForUpdateDb = async () => {
    const credentials = Realm.Credentials.apiKey(apiKey);
    try {
        // Authenticate the user
        const user = await app1.logIn(credentials);
        // `App.currentUser` updates to match the logged in user
        console.assert(user.id === app1.currentUser.id);
        return user;
    } catch (err) {
        console.error("Failed to log in", err);
    }

}

const getUser = async () => {
    const credentials = Realm.Credentials.anonymous();
    // Authenticate the user
    const user = await app.logIn(credentials);
    
    // debugger;
    // app.currentUser.custom_data = {dbName: "test"};
    // app.currentUser.refreshCustomData();
    // debugger;
    return user;
}

// async function getValidAccessToken() {
//     // Guarantee that there's a logged in user with a valid access token
//     if (!app.currentUser) {
//         // If no user is logged in, log in an anonymous user. The logged in user will have a valid
//         // access token.
//         await app.logIn(Realm.Credentials.anonymous());
//     } else {
//         // An already logged in user's access token might be stale. To guarantee that the token is
//         // valid, we refresh the user's custom data which also refreshes their access token.
//         await app.currentUser.refreshCustomData();
//     }
//     mongo = app.currentUser.mongoClient(CLUSTER_NAME);
//     mongodb = mongo.db(DATABASE_NAME);
//     return app.currentUser.accessToken;
// }

export { mongodb, getUser, getUserForUpdateDb };
